<!--
 * @Description: 质检配置
 * @Author: ChenXueLin
 * @Date: 2021-09-22 11:48:08
 * @LastEditTime: 2021-12-25 16:57:52
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <div class="box">
        <div class="title">质检评分标准</div>
        <div class="hasConfig">已配置 {{ number }} 个评分标准</div>
        <el-button type="primary" @click="handleScore">质检标准配置</el-button>
      </div>
      <!-- <div class="box">
        <span class="title">质检评分及格线</span>
        <span class="hasConfig">60分</span>
        <el-button type="primary">编辑分数</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { getConfigNumber } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "config",
  components: {},
  data() {
    return {
      loading: false,
      number: ""
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //查询服务单质检详情
    async getNumber() {
      try {
        this.loading = true;
        let res = await getConfigNumber();
        this.number = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleScore() {
      this.routerPush({
        name: "qualityInspectionConfig/scoringStandardConfig",
        params: {
          refresh: true
        }
      });
    }
  },
  created() {
    this.getNumber();
  }
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  .title {
    font-size: 16px;
    font-weight: 550;
    width: 150px;
  }
  .hasConfig {
    font-size: 14px;
    margin-left: 30px;
    margin-right: 30px;
    width: 250px;
  }
}
</style>
